<!-- 供应商 - 订单详情 -->
<template>
  <div class="container">
    <div class="container_content">
      <!-- 左侧图片 -->
      <div class="container_content_left">
        <img :src="currentImgSrc" alt="">
        <div class="container_content_left_imgs">
          <img v-for="(img, index) in imgList" :key="index" :src="img" @click="changeImg(img)" :class="{ 'container_content_left_imgs_isSelected': img === currentImgSrc }" alt="">
        </div>
      </div>

      <!-- 右侧 -->
      <div class="container_content_right">

        <!-- 需求详情  requirement-->
        <div class="container_content_right_requirement requirement">

          <!-- 标题 -->
          <div class="container_content_right_requirement_titleBox requirement_titleBox">
            <div class="container_content_right_requirement_titleBox_title">{{
              order.clientRequirement?.productName
            }}
            </div>
            <!-- <div class="container_content_right_requirement_titleBox_quantity">
              <span>Quantity</span>{{ order.clientRequirement?.quantity }}
            </div> -->
          </div>

          <!-- 详情 -->
          <div class="container_content_right_requirement_details">
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_item">
                <div class="container_content_right_requirement_details_list_title">Quantity</div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ order.clientRequirement?.quantity }}
                </div>
              </div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_item">
                <div class="container_content_right_requirement_details_list_title">Product Size (mm)</div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ order.clientRequirement?.specification }}
                </div>
              </div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_item">
                <div class="container_content_right_requirement_details_list_title">Material</div>
                <div class="container_content_right_requirement_details_list_value">{{
                  order.clientRequirement?.materialName
                }}
                </div>
              </div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_item">
                <div class="container_content_right_requirement_details_list_title">Colour</div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ order.clientRequirement?.pantoneCode }}
                </div>
              </div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_item">
                <div class="container_content_right_requirement_details_list_title">Process Requirement</div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ order.clientRequirement?.processRequirement }}
                </div>
              </div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_item">
                <div class="container_content_right_requirement_details_list_title">Other Requirement</div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ order.clientRequirement?.otherSpecialRequirent }}
                </div>
              </div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_item">
                <div class="container_content_right_requirement_details_list_title">Remark</div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ order.clientRequirement?.remark }}
                </div>
              </div>
            </div>
          </div>

          <!-- 产品2 -->
          <div class="container_content_right_requirement_details" v-for="(item, index) in order.clientRequirement?.productInfoList">
            <div class="container_content_right_requirement_details_title">
              {{ item.productName }}
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_item">
                <div class="container_content_right_requirement_details_list_title">Quantity</div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ item.quantity }}
                </div>
              </div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_item">
                <div class="container_content_right_requirement_details_list_title">Product Size (mm)</div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ item.specification }}
                </div>
              </div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_item">
                <div class="container_content_right_requirement_details_list_title">Material</div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ item.material }}
                </div>
              </div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_item">
                <div class="container_content_right_requirement_details_list_title">Colour</div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ item.pantoneCode }}
                </div>
              </div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_item">
                <div class="container_content_right_requirement_details_list_title">Process Requirement</div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ item.processRequirement }}
                </div>
              </div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_item">
                <div class="container_content_right_requirement_details_list_title">Other Requirement</div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ item.otherSpecialRequirent }}
                </div>
              </div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_item">
                <div class="container_content_right_requirement_details_list_title">remark</div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ item.remark }}
                </div>
              </div>
            </div>
          </div>

          <!--通用数据 -->
          <div class="container_content_right_requirement_details commonData">
            <div class="container_content_right_requirement_details_list commonData_list">
              <div class="container_content_right_requirement_details_list_item">
                <div class="container_content_right_requirement_details_list_title">Leadtime Requirment</div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ order.clientRequirement?.expectedDeliveryDate }}
                </div>
              </div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_item">
                <div class="container_content_right_requirement_details_list_title">Delivery address</div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ order.clientRequirement?.deliveryAddressId }}
                </div>
              </div>
            </div>

            <div class="container_content_right_requirement_details_list" v-if="userType === '2'">
              <div class="container_content_right_requirement_details_list_item">
                <div class="container_content_right_requirement_details_list_title">Overall Budget</div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ order.clientRequirement?.overallBudget }}
                </div>
              </div>
            </div>

            <!-- <div class="container_content_right_requirement_details_list file" v-if="order.clientImageList?.length > 0">
              <div class="file_left">
                <div class="container_content_right_requirement_details_list_title">Original Artwork
                </div>
                <div class="container_content_right_requirement_details_list_value">Design draft.ai
                </div>
              </div>
              <div class="file_download" @click="getClientArtByRequirementIdMethod">Download</div>
            </div> -->
          </div>

          <!-- Expand/Collapse -->
          <!-- <div class="container_content_right_requirement_expand" @click="showRequirementDetail">
            <div class="container_content_right_requirement_expand_box">
              <span>Expand</span>
              <img src="../../assets/images/customer/Expand.png" alt="">
            </div>
          </div> -->
        </div>

        <!-- 报价单  quotation-->
        <div class="container_content_right_requirement quotation" v-if="order.supplierQuotationList">

          <!-- 标题 -->
          <div class="container_content_right_requirement_titleBox">
            <div class="container_content_right_requirement_titleBox_title">Quotation</div>
            <div class="container_content_right_requirement_titleBox_left" @click="openCarbonValue">
              <img src="../../assets/images/home/carbonFootprint_white.png" alt="">
              <span>{{ thousands(order.supplierQuotationList[0].totalco) }}kg</span>
              <span class="co">CO<sub>2</sub> </span>
              <img src="../../assets/images/customer/white_right.png" alt="">
            </div>
          </div>

          <!-- 详情 -->
          <div class="container_content_right_requirement_details" v-show="isShowQuotationDetail">
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_item">

                <div class="container_content_right_requirement_details_list_title">
                  HS Code
                </div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ order.supplierQuotationList == null ? '-' : (order.supplierQuotationList[0].hscode == null ? "" : order.supplierQuotationList[0].hscode) }}
                </div>
              </div>

              <!--              <div class="container_content_right_requirement_details_list_item">-->

              <!--                <div class="container_content_right_requirement_details_list_title">-->
              <!--                  Official order delivery time-->
              <!--                </div>-->
              <!--                <div class="container_content_right_requirement_details_list_value">-->
              <!--                  {{ order.supplierQuotationList == null ? '-' : (order.supplierQuotationList[0].productionDuration == null ? "" : order.supplierQuotationList[0].productionDuration) }}-->
              <!--                </div>-->
              <!--              </div>-->

              <div class="container_content_right_requirement_details_list_item">

                <div class="container_content_right_requirement_details_list_title">
                  Sample charge
                </div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ order.supplierQuotationList == null ? '' : (order.supplierQuotationList[0].sampleUnitPrice == null ? "" : currency) }} {{ order.supplierQuotationList == null ? '-' : (order.supplierQuotationList[0].sampleUnitPrice == null ? "" : order.supplierQuotationList[0].sampleUnitPrice) }}
                </div>
              </div>

              <div class="container_content_right_requirement_details_list_item">

                <div class="container_content_right_requirement_details_list_title">
                  Sample Leadtime
                </div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ order.supplierQuotationList == null ? '-' : (order.supplierQuotationList[0].sampleGenerationPeriod == null ? "" : order.supplierQuotationList[0].sampleGenerationPeriod) }}
                </div>
              </div>

              <div class="container_content_right_requirement_details_list_item">

                <div class="container_content_right_requirement_details_list_title">
                  Pcs/Ctn
                </div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ order.supplierQuotationList == null ? '-' : (order.supplierQuotationList[0].pcsCtnSingle == null ? "" : order.supplierQuotationList[0].pcsCtnSingle) }}
                </div>
              </div>

              <div class="container_content_right_requirement_details_list_item">

                <div class="container_content_right_requirement_details_list_title">
                  Carton Size(mm)
                </div>
                <div class="container_content_right_requirement_details_list_value">
                  {{
                    order.supplierQuotationList == null ? '-' : (order.supplierQuotationList[0].ctnSizeSingle == null ? "" : order.supplierQuotationList[0].ctnSizeSingle)
                  }}
                </div>
              </div>
              <div class="container_content_right_requirement_details_list_item">

                <div class="container_content_right_requirement_details_list_title">
                  Total CBM
                </div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ order.supplierQuotationList == null ? '-' : (order.supplierQuotationList[0].totalCbm == null ? "" : order.supplierQuotationList[0].totalCbm) }}
                </div>
              </div>
              <div class="container_content_right_requirement_details_list_item">

                <div class="container_content_right_requirement_details_list_title">
                  Gross Weight(kg)
                </div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ order.supplierQuotationList == null ? '-' : (order.supplierQuotationList[0].totalGrossWeight == null ? "" : order.supplierQuotationList[0].totalGrossWeight) }}
                </div>
              </div>

              <div class="container_content_right_requirement_details_list_item">

                <div class="container_content_right_requirement_details_list_title">
                  FOB/CIF (unit)
                </div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ order.supplierQuotationList == null ? '-' : (order.supplierQuotationList[0].fobcif == null ? "" : order.supplierQuotationList[0].fobcif) }}
                </div>
              </div>

              <div class="container_content_right_requirement_details_list_item">

                <div class="container_content_right_requirement_details_list_title">
                  Special charges
                </div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ order.supplierQuotationList == null ? '-' : (order.supplierQuotationList[0].specialCharges == null ? "" : order.supplierQuotationList[0].specialCharges) }}
                </div>
              </div>

              <!--              <div class="container_content_right_requirement_details_list_item">-->

              <!--                <div class="container_content_right_requirement_details_list_title">-->
              <!--                  Energy Use-->
              <!--                </div>-->
              <!--                <div class="container_content_right_requirement_details_list_value">-->
              <!--                  {{order.supplierQuotationList[0].energyuse == null ? "" : order.supplierQuotationList[0].energyuse}}</div>-->
              <!--              </div>-->

              <div class="container_content_right_requirement_details_list_item" v-if="order.supplierQuotationList[0].specialCharges !== 'None'">

                <div class="container_content_right_requirement_details_list_title">
                  Charge Amount
                </div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ order.supplierQuotationList == null ? '' : (order.supplierQuotationList[0].chargeAmount == null ? "" : currency) }} {{ order.supplierQuotationList == null ? '-' : (order.supplierQuotationList[0].chargeAmount == null ? "" : order.supplierQuotationList[0].chargeAmount) }}
                </div>
              </div>

              <div class="container_content_right_requirement_details_list_item">

                <div class="container_content_right_requirement_details_list_title">
                  Unit Price
                </div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ currency }} {{ order.supplierQuotationList == null ? '-' : (order.supplierQuotationList[0].unitPrice == null ? "" : thousand(order.supplierQuotationList[0].unitPrice)) }}
                </div>
              </div>

              <div class="container_content_right_requirement_details_list_item">

                <div class="container_content_right_requirement_details_list_title">
                  Price
                </div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ currency }} {{ order.supplierQuotationList == null ? '-' : (order.supplierQuotationList[0].totalprice == null ? "" : thousand(order.supplierQuotationList[0].totalprice)) }}
                </div>
              </div>

              <div class="container_content_right_requirement_details_list_item" v-if="order.sampleNum">

                <div class="container_content_right_requirement_details_list_title">
                  Plain Sample
                </div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ order.sampleNum == null ? "" : order.sampleNum }}
                </div>
              </div>

              <div class="container_content_right_requirement_details_list_item" v-if="order.customizedSampleNum">

                <div class="container_content_right_requirement_details_list_title">
                  Customized Sample
                </div>
                <div class="container_content_right_requirement_details_list_value">
                  {{ order.customizedSampleNum == null ? "" : order.customizedSampleNum }}
                </div>
              </div>

            </div>
            <!-- Expand/Collapse -->
          </div>
          <div class="container_content_right_requirement_expand" @click="showQuotationDetail">
            <div class="container_content_right_requirement_expand_box">
              <span>Expand</span>
              <img src="../../assets/images/customer/Expand.png" alt="">
            </div>
          </div>
        </div>
        <!-- 订单状态 -->
        <div class="container_content_right_status">
          <div class="container_content_right_status_step">
            <el-steps :active="orderStatus" finish-status="success" direction="vertical" v-if="order.orderType === 1">
              <el-step :title="item.state" :description="item.time" v-for="(item, index) in stateOptionsOnlySample" :key="index">
              </el-step>
            </el-steps>
            <el-steps :active="orderStatus" finish-status="success" direction="vertical" v-else-if="order.orderType === 2">
              <el-step :title="item.state" :description="item.time" v-for="(item, index) in stateOptions" :key="index">
              </el-step>
            </el-steps>
            <el-steps :active="orderStatus" finish-status="success" direction="vertical" v-else-if="order.orderType === 3">
              <el-step :title="item.state" :description="item.time" v-for="(item, index) in stateOptionsWithSample" :key="index">
              </el-step>
            </el-steps>
            <div v-if="(order.orderType === 1 && orderStatus !== 2) || (order.orderType === 2 && orderStatus !== 6) || (order.orderType === 3 && orderStatus !== 8)" class="container_content_right_status_step_btn" @click="nextStep">Next step
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 查看碳值的弹窗 -->
    <ViewCarbonValue v-if="order.supplierQuotationList" v-on:customEvent="closeCarbonValue" v-show="showCarbonValue" :quotation=order.supplierQuotationList[0] />

    <!-- foot   -->
    <Foot />
  </div>
</template>

<!-- 报价单详情 -->
<script setup name="QuotationDetail">
import { ref, reactive, provide } from 'vue'
import { useRoute } from 'vue-router'
import Foot from '../../components/Foot.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getOrderDetail, updateOrder, getOrderTimeLines } from '@/api/order'
import { thousands } from '@/utils/thousands'
import { getUserType } from '@/utils/auth'
// 碳值弹窗
import ViewCarbonValue from '../../views/customer/dialog/ViewCarbonValue.vue'
import { getClientArtByRequirementId } from '@/api/demand'
import { thousand } from '@/utils/thousand'
// 当前报价单的状态，1：未确认，2：签大货单，不需要样品，3：签大货单，需要样品，4:样品单 5：已拒绝
const status = ref(1)
const route = useRoute()
const orderId = route.query.orderId
console.log('orderId:' + orderId)
const order = ref({})
const orderStatus = ref(0)
const currency = ref('')
// 切换图片
const imgList = ref([])
const currentImgSrc = ref('https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/co-track/goodavt.png')
const changeImg = (img) => {
  currentImgSrc.value = img
  // 如果后缀为pdf,就使用默认图片
  if (img.indexOf('.pdf') !== -1) {
    currentImgSrc.value = 'https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/co-track/goodavt.png'
  }
}

const userType = ref('0')
userType.value = getUserType().toString()

const stateOptionsOnlySample = ref([
  {
    state: 'Raw Matrial Processing',
    time: '',
    status: 2
  },
  {
    state: 'Sample Production',
    time: '',
    status: 101
  },
  {
    state: 'Sample Shipping',
    time: '',
    status: 102
  }

])

const stateOptions = ref([
  {
    state: 'Raw Matrial Processing',
    time: '',
    status: 2
  },
  {
    state: 'Production',
    time: '',
    status: 3
  },
  {
    state: 'Packaging',
    time: '',
    status: 4
  },
  {
    state: 'Inspection',
    time: '',
    status: 5
  },
  {
    state: 'Sending To Port of Departure',
    time: '',
    status: 6
  },
  {
    state: 'In Transit',
    time: '',
    status: 7
  },
  {
    state: 'Local Delivery',
    time: '',
    status: 8
  }
])

const stateOptionsWithSample = ref([
  {
    state: 'Raw Matrial Processing',
    time: '',
    status: 2
  },
  {
    state: 'Sample Production',
    time: '',
    status: 101
  },
  {
    state: 'Sample Shipping',
    time: '',
    status: 102
  },
  {
    state: ' Mass Production',
    time: '',
    status: 3
  },
  {
    state: 'Packaging',
    time: '',
    status: 4
  },
  {
    state: 'Inspection',
    time: '',
    status: 5
  },
  {
    state: 'Sending To Port of Departure',
    time: '',
    status: 6
  },
  {
    state: 'In Transit',
    time: '',
    status: 7
  },
  {
    state: 'Local Delivery',
    time: '',
    status: 8
  }
])
const getOrderTimeLine = async () => {
  const result = await getOrderTimeLines(orderId)
  console.log('timeline result==', result)
  if (result.code === 200) {
    const timelineList = result.data
    if (timelineList.length > 0) {
      console.log('orderStatus==', order.value.orderStatus)
      console.log('orderType==', order.value.orderType)
      if (order.value.orderType === 1) {
        for (const item of timelineList) {
          for (const items of stateOptionsOnlySample.value) {
            if (item.status === items.status) {
              items.time = item.createTime
            }
          }
        }
      } else if (order.value.orderType === 2) {
        for (const item of timelineList) {
          for (const items of stateOptions.value) {
            if (item.status === items.status) {
              items.time = item.createTime
            }
          }
        }
      } else if (order.value.orderType === 3) {
        for (const item of timelineList) {
          for (const items of stateOptionsWithSample.value) {
            if (item.status === items.status) {
              items.time = item.createTime
            }
          }
        }
      }
    }
  }
}

const getOrderById = async () => {
  const result = await getOrderDetail(orderId)
  if (result.code === 200) {
    order.value = result.data
    console.log('order', order.value)
    if (order.value?.clientImageList != null) {
      if (order.value?.clientImageList.length > 0) {
        for (var item of order.value?.clientImageList) {
          console.log(item.imageUrl)
          if (item.imageUrl.indexOf('.pdf') !== -1) {
            item.imageUrl = '	https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/co-track/goodimage.png'
          }
          imgList.value.push(item.imageUrl)
        }
        if (imgList.value.length > 0) {
          currentImgSrc.value = imgList.value[0]
        }
      }
    }
    if (order.value.supplierQuotationList[0].currency) {
      currency.value = order.value.supplierQuotationList[0].currency
      if (order.value.supplierQuotationList[0].currency === 'EUR') {
        currency.value = '€'
      } else if (order.value.supplierQuotationList[0].currency === 'USD') {
        currency.value = '$'
      } else if (order.value.supplierQuotationList[0].currency === 'CNY') {
        currency.value = '¥'
      }
    }
    console.log('supplierQuotationList', order.value.supplierQuotationList[0].totalco)
    if (order.value.orderType === 1) {
      switch (order.value.orderStatus) {
      case 2:
        orderStatus.value = 0
        break
      case 101:
        orderStatus.value = 1
        break
      case 102:
        orderStatus.value = 2
        break
      case 103:
        orderStatus.value = 2
        break
      case 104:
        orderStatus.value = 2
        break
      case 9:
        orderStatus.value = 2
        break
      }
    } else if (order.value.orderType === 2) {
      switch (order.value.orderStatus) {
      case 2:
        orderStatus.value = 0
        break
      case 3:
        orderStatus.value = 1
        break
      case 4:
        orderStatus.value = 2
        break
      case 5:
        orderStatus.value = 3
        break
      case 6:
        orderStatus.value = 4
        break
      case 7:
        orderStatus.value = 5
        break
      case 8:
        orderStatus.value = 6
        break
      case 9:
        orderStatus.value = 6
        break
      }
    } else if (order.value.orderType === 3) {
      switch (order.value.orderStatus) {
      case 2:
        orderStatus.value = 0
        break
      case 101:
        orderStatus.value = 1
        break
      case 102:
        orderStatus.value = 2
        break
      case 3:
        orderStatus.value = 3
        break
      case 4:
        orderStatus.value = 4
        break
      case 5:
        orderStatus.value = 5
        break
      case 6:
        orderStatus.value = 6
        break
      case 7:
        orderStatus.value = 7
        break
      case 8:
        orderStatus.value = 8
        break
      case 9:
        orderStatus.value = 8
        break
      }
    }
  }
  getOrderTimeLine()
}

getOrderById()
const quatationDetailId = reactive({
  id: 2356,
  orderType: 'sampleOrder',
  promptContent: 'Are you sure you don\'t accept the supplier\'s quotation?'
})
// 使用 provide 函数向接收弹窗和查看碳值提供数据
provide('quatationDetailId', quatationDetailId)

// 产品详情是否展开
const isShowRequirementDetail = ref(true)
const showRequirementDetail = () => {
  isShowRequirementDetail.value = !isShowRequirementDetail.value
  console.log(isShowRequirementDetail.value)
}

// 报价单是否展开
const isShowQuotationDetail = ref(true)
const showQuotationDetail = () => {
  isShowQuotationDetail.value = !isShowQuotationDetail.value
  console.log(isShowQuotationDetail.value)
}

// 查看碳值的弹窗
const showCarbonValue = ref(false)
// 打开查看碳值的弹窗
const openCarbonValue = () => {
  showCarbonValue.value = true
}
// 关闭查看碳值的弹窗
const closeCarbonValue = (data) => {
  if (data === 'close') {
    showCarbonValue.value = false
  }
}

// 变更订单状态
// const stateOptions = ref(['样品订单生产中', '生成完成已发货', '运输中', '客户确认样品单收货', '正式订单生产中', '正式订单生产完成已发货', '正式订单运输中'])

const changeOrderStatus = async () => {
  // 点击按钮增加一步
  orderStatus.value++
  let status = 0
  if (order.value.orderType === 1) {
    if (orderStatus.value > stateOptionsOnlySample.value.length - 1) {
      orderStatus.value = stateOptionsOnlySample.value.length - 1
    }
    console.log(orderStatus.value, stateOptionsOnlySample.value[orderStatus.value].status)
    status = stateOptionsOnlySample.value[orderStatus.value].status
  } else if (order.value.orderType === 2) {
    if (orderStatus.value > stateOptions.value.length - 1) {
      orderStatus.value = stateOptions.value.length - 1
    }
    console.log(orderStatus.value, stateOptions.value[orderStatus.value].status)
    if (stateOptions.value[orderStatus.value].status === 6) {
      ElMessage({
        message: 'Will not be able to operate.',
        type: 'warning'
      })
      orderStatus.value--
      return
    }
    status = stateOptions.value[orderStatus.value].status
  } else {
    if (orderStatus.value > stateOptionsWithSample.value.length - 1) {
      orderStatus.value = stateOptionsWithSample.value.length - 1
    }
    console.log(orderStatus.value, stateOptionsWithSample.value[orderStatus.value].status)
    if (stateOptionsWithSample.value[orderStatus.value].status === 6) {
      ElMessage({
        message: 'Will not be able to operate.',
        type: 'warning'
      })
      orderStatus.value--
      return
    }
    status = stateOptionsWithSample.value[orderStatus.value].status
  }
  const result = await updateOrder({ orderId: orderId, orderStatus: status })
  if (result.code === 200) {
    console.log(result)
    getOrderById()
  }
}

// 退出进行下一步
const nextStep = () => {
  ElMessageBox.confirm(
    'Confirm to proceed to the next step?',
    'Order status change',
    {
      distinguishCancelAndClose: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      autofocus: false
    }
  )
    .then(() => {
      // 确认进行下一步
      changeOrderStatus()
    })
    .catch(() => {

    })
}

const getClientArtByRequirementIdMethod = async () => {
  if (order.value != null) {
    if (order.value.clientRequirement != null) {
      const result = await getClientArtByRequirementId(order.value.clientRequirement.requirementId)
      if (result.code === 200) {
        if (result.data.length > 0) {
          for (const url of result.data) {
            downloadFile(url)
          }
        }
      }
    }
  }
}

const downloadFile = (url) => {
  // const a = document.createElement('a')
  // a.href = url // 文件链接
  // a.download = url // 文件名，跨域资源download无效
  // a.click()
  // a.remove()
  window.open(url)
}

</script>

<style lang="scss" scoped>
.container {
  background-color: #ffffff;
  line-height: 1;
  text-align: left;
  display: flex;
  flex-direction: column;

  &_content {
    width: 1400px;
    margin: 0 auto;
    padding-top: 50px;
    display: flex;
    justify-content: space-between;

    // 左侧图片
    &_left {
      width: 30%;
      font-size: 0;

      img {
        width: 360px;
        height: 360px;
        overflow: hidden;
        object-fit: cover;
        border-radius: 6px;
      }

      &_imgs {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        img {
          width: 60px;
          height: 60px;
          overflow: hidden;
          object-fit: cover;
          border-radius: 4px;
          margin-right: 15px;
          cursor: pointer;
          opacity: 0.3;

          &:nth-child(5n) {
            margin-right: 0px;
          }
        }

        &_isSelected {
          opacity: 1 !important;
          box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, 0.5);
        }
      }
    }

    &_right {
      width: 66%;
      &_requirement {
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
        overflow: hidden;
        // margin-left: 410px;
        margin-bottom: 50px;

        // 标题
        &_titleBox {
          width: 100%;
          border: 1px solid rgba(255, 255, 255, 1);
          box-sizing: border-box;
          border-bottom: none;
          background: linear-gradient(180deg,
              rgba(39, 174, 96, 0.1) 0%,
              rgba(39, 174, 96, 0) 100%);
          display: flex;
          justify-content: space-between;

          &_title {
            font-size: 24px;
            color: rgba(24, 24, 24, 1);
            font-weight: 700;
            margin: 40px 0 0 50px;
          }

          &_quantity {
            font-size: 20px;
            color: rgba(24, 24, 24, 1);
            font-weight: 600;
            margin: 40px 50px 20px 0;

            span {
              color: rgba(173, 173, 173, 1);
              font-weight: 300;
              margin-right: 10px;
            }
          }
        }

        // 产品详情
        &_details {

          &_title {
            font-size: 24px;
            color: rgba(24, 24, 24, 1);
            font-weight: 700;
            padding-top: 20px;
            margin: 20px 50px 0px 50px;
          }

          &_list {
            margin: 0 50px;

            &_item {
              padding: 20px 0;
              width: 100%;
              display: flex;
              border-bottom: 1px solid rgba(240, 240, 240, 1);
            }

            &_title {
              font-size: 20px;
              color: rgba(150, 150, 150, 1);
              font-weight: 300;
              line-height: 16px;
              width: 200px;
            }

            &_value {
              margin-left: 10px;
              font-size: 20px;
              color: rgba(24, 24, 24, 1);
            }

            &:last-child {
              border: none;
            }
          }

          .file {
            display: flex;
            justify-content: space-between;

            &_left {
              display: flex;
              width: 500px;
            }

            &_download {
              font-size: 20px;
              color: rgba(39, 174, 96, 1);
              cursor: pointer;
            }
          }
        }

        // 通用数据
        .commonData {

          &_list {
            display: flex;
            margin: 0 50px;
            padding: 0;

            &:first-child {
              border-top: 1px dashed rgba(39, 174, 96, .6) !important;
              margin-top: 50px !important;
            }
          }
        }

        // 折叠
        &_expand {
          padding: 0 50px 30px;

          &_box {
            font-size: 0;
            color: rgba(36, 89, 2, 1);
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 30px;
            border-top: 1px solid rgba(240, 240, 240, 1);
            cursor: pointer;

            span {
              font-size: 20px;
            }

            img {
              width: 14px;
              height: 14px;
            }
          }
        }
      }

      // 需求详情
      .requirement {
        &_titleBox {
          background: #ffffff;
        }
      }

      // 报价详情
      .quotation {
        margin-bottom: 60px;

        .container_content_right_requirement_titleBox {
          display: flex;
          align-items: center;

          &_title {
            line-height: 50px;
            margin: 30px 0 30px 50px;
          }

          &_left {
            height: 50px;
            margin-right: 50px;
            border-radius: 6px;
            background-color: rgba(39, 174, 96, 1);
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 20px;
            cursor: pointer;

            img {
              width: 24px;
              height: 24px;
              margin-right: 15px;

              &:last-child {
                width: 18px;
                height: 18px;
                margin-right: 15px;
              }
            }

            span {
              font-size: 28px;
              line-height: 30px;
              font-weight: 800;
              color: #ffffff;

              &+.co {
                font-size: 20px;
                color: rgba(255, 255, 255, 0.8);
                font-weight: 300;
                margin-left: 10px;
                margin-right: 20px;
              }
            }
          }
        }
      }

      &_status {
        width: 790px;

        &_step {
          // width: 100%;
          margin: 0 50px 100px 50px;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          &_btn {
            width: 80px;
            padding: 15px 20px;
            border: 1px solid rgba(39, 174, 96, 1);
            text-align: center;
            border-radius: 6px;
            font-size: 20px;
            color: rgba(39, 174, 96, 1);

            o &:hover {
              background-color: rgba(39, 174, 96, 1);
              color: #ffffff;
              cursor: pointer;
            }
          }

          :deep .el-step {
            flex-basis: 80px !important;

            .el-step__line {
              width: 1px;
            }

            .el-step__title {
              font-size: 20px;
            }

            .el-step__head.is-success {
              color: rgb(0, 0, 0);
              border-color: rgb(0, 0, 0);
            }

            .el-step__description.is-success {
              color: rgb(0, 0, 0);
            }

            .el-step__title.is-success {
              color: rgb(0, 0, 0);
            }

            .el-step__head.is-process {
              color: rgba(39, 174, 96, 1);
              border-color: rgba(39, 174, 96, 1);
            }

            .el-step__title.is-process {
              color: rgba(39, 174, 96, 1);
              border-color: rgba(39, 174, 96, 1);
              font-size: 20px;
            }

            .el-step__description.is-process {
              font-size: 20px;
              color: rgba(39, 174, 96, 1);
            }
          }
        }
      }
    }
  }
}
</style>
